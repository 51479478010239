<template>
  <el-dialog v-model="dialogVisible" title="回填验证码" :close-on-click-modal="false" width="560px">
    <div class="dp-f mt-20 mb-30">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
        class="demo-ruleForm w100" label-position="left">
        <el-form-item label="姓名" prop='name'>
          <el-input v-model="ruleForm.name" :maxlength="config.inputMaxlength" show-word-limit disabled />
        </el-form-item>
        <el-form-item label="验证码" prop='code' class="is-required">
          <div class="w100 dp-f">
            <el-input class="w100" v-model="ruleForm.code" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入验证码" maxlength="6" />
            <verificationCode class="ml-12" ref="verificationCodeRef" CSStype="2" @Emit="send"></verificationCode>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f jc-c">
        <!-- <oabutton class="m-0-at" width='462' height='52' style="border-radius: 4px;font-size: 18px;" title="测试用直接下一步"
          CSStype=2 @buttonclick="dialogVisible = false, emit('Emit', state.key, true)"></oabutton> -->
        <oabutton v-model:loading="dialogloading" class="m-0-at" width='462' height='52'
          style="border-radius: 4px;font-size: 18px;" title="确定" CSStype=2 @buttonclick="submitForm"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, computed } from 'vue'
import { phoneDesensitization } from "@/hooks/desensitization.js"
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { httpToken } from "@/utils/request";
import { useStore } from "vuex";
import verificationCode from "@/components/button/verificationCode.vue"//验证码组件
import qs from "qs";
const store = useStore();
const props = defineProps({
  // type 1 登录 2 注册 3 重置密码 4 修改密码 5 实名认证 6 设置签署密码 7 签署密码校验 8 用户注销 9 新任务通知 10修改手机号
  smstype: {
    type: Number,
    default: () => {
      return 4
    }
  },
})
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const verificationCodeRef = ref()//验证码组件ref
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  name: '',//
  code: '',//验证码
})
const rules = reactive({//from 表单校验
  code: [
    { required: true, message: '请输入验证码' },
    { min: 6, max: 6, message: '验证码的长度为6', trigger: 'blur' }
  ],
})
const userInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info
})
const getData = () => {
  return httpToken({
    method: "post",
    url: '/admin/certification/selectById',
    data: qs.stringify({
      companyId: store.state.realName.companyId,
    }),
  })
}
// 发送验证码
const send = (() => {
  httpToken({
    method: "post",
    url: '/admin/certification/sendCompanyCertificationSMS',
    data: qs.stringify({
      companyId: store.state.realName.companyInfo.companyId,
    })
  }).then(res => {
    unref(verificationCodeRef).send()
    handleMessage('验证码已发送', 'success')
  })
})
// 表单重置
let fromDataReset = (() => {
  objdata_ret(ruleForm)
})
let show = (() => {
  getData().then(res=>{
    fromDataReset()
    ruleForm.name = '使用 手机号 ' + phoneDesensitization(res.data.juridicalPhone).value + ' 验证'
    dialogloading.value = false
    dialogVisible.value = true
    nextTick(() => {
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
  
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      httpToken({
        method: "post",
        url: '/admin/certification/checkLegalSMS',
        data: qs.stringify({
          'companyId': store.state.realName.companyInfo.companyId,
          'code': ruleForm.code
        })
      }).then(res => {
        dialogVisible.value = false
        dialogloading.value = false
        emit('Emit')
      }).catch(() => {
        dialogloading.value = false
      })
    } else {
      dialogloading.value = false
    }
  })
}
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/settingFrom.scss"; //element 样式重置

::v-deep .el-form-item__label {
  display: none;
}
</style>